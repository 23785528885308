import React from "react"
import HTMLReactParser from "html-react-parser"
import { ImgTextCustom } from "customBlocks/ImgTextCustom"
import { DoubleImgCustom } from "customBlocks/DoubleImgCustom"
import { Gallery } from "customBlocks/Gallery"
import { CustomEmbeddedVideo } from "customBlocks/CustomEmbeddedVideo"

function getPhotos(children) {
  const data = children[0].children
  let photos = []
  data.forEach((img) => {
    const theImg = img.children[0].children[0]

    const theCaption = img.children[0].children[1]

    if (theImg.attribs) {
      photos.push({
        src: theImg.attribs.src,
        srcSet: theImg.attribs.srcset,
        caption: theCaption ? theCaption.children[0].data : "",
      })
    }
  })

  return photos
}

// CTA Block
const getFeatureBlock = (attribs) => {
  const { image, message, link, linkText, heading } = attribs

  return (
    <ImgTextCustom
      heading={heading}
      message={message}
      link={link}
      linkText={linkText}
      img={image}
    />
  )
}

// Custom Video Block
const getVideoBlock = (attribs) => {
  const { thumbnailSrcSet, thumbnailURL, video, autoplay } = attribs
  const shouldPlay = autoplay === "1" ? true : false

  return (
    <CustomEmbeddedVideo
      thumbnailSrcSet={thumbnailSrcSet}
      thumbnailURL={thumbnailURL}
      video={video}
      shouldPlay={shouldPlay}
    />
  )
}

// 2 Images Block
const getTwoImagesBlock = (data) => {
  return <DoubleImgCustom images={data} />
}

const getFullWidthImage = (attribs, classes) => {
  const { src, alt, srcset } = attribs

  return (
    <>
      <figure className={`${classes}`}>
        <img
          src={src}
          alt={alt}
          srcSet={srcset}
          loading="lazy"
          sizes="(max-width: 1280px) 100vw, 1160px"
        />
      </figure>
    </>
  )
}

const getDefaultImage = (attribs) => {
  const { src, alt, srcset } = attribs

  return (
    <>
      <figure className={`wp-block-image`}>
        <img
          src={src}
          alt={alt}
          srcSet={srcset}
          loading="lazy"
          sizes="(max-width: 1024px) 100vw, 800px"
        />
      </figure>
    </>
  )
}
// Parse Custom Blocks
export function parseBlockContent(markup) {
  const Content = HTMLReactParser(markup, {
    replace: ({ attribs, children, next }) => {
      if (!attribs) return

      if (attribs.class && attribs.class.includes("acf-block--cta")) {
        const data = JSON.parse(attribs.data)
        return getFeatureBlock(data)
      }
      if (
        attribs.class &&
        attribs.class.includes("acf-block--video_embedded")
      ) {
        const data = JSON.parse(attribs.data)
        return getVideoBlock(data)
      }

      if (attribs.class && attribs.class.includes("wp-block-gallery")) {
        return <Gallery imgs={getPhotos(children)} />
      }

      if (attribs.class && attribs.class.includes("acf-block--two-images")) {
        const data = JSON.parse(attribs.data)
        return getTwoImagesBlock(data)
      }

      // if (attribs.class && attribs.class.includes("wp-block-image alignwide")) {

      //   console.log(children)

      //   return false;
      //   return getFullWidthImage(children[0].attribs, attribs.class)
      // }

      // if (attribs.class && attribs.class.includes("wp-block-image alignfull")) {
      //   return getFullWidthImage(children[0].attribs, attribs.class)
      // }

      if (attribs.class && attribs.class.includes("align-left")) {
        return getDefaultImage(children[0].attribs)
      }
    },
  })

  return Content
}
