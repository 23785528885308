import styled from "styled-components"
import { device } from "breakpoints"
import { Button } from "ui/Button"

export const ImgTextCustomContainer = styled.div`
  width: calc(100% + 50px);
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 40px 0 40px -25px;
  position: relative;
  background: none;
  overflow: hidden;

  @media ${device.tablet} {
    flex-direction: row;
    width: 100%;
    align-items: center;
    position: relative;
  }

  :hover {
    .button-link {
      ::before {
        opacity: 1;
      }
    }
  }
`
export const ImgWrapper = styled.div`
  position: relative;
  z-index: 1;

  > div > div {
    padding-bottom: 72% !important;
  }
  @media ${device.tablet} {
    flex-direction: row;
    width: 45%;
    position: absolute;
    top:50%;
    transform:translateY(-50%);

    > div > div {
      padding-bottom: 87% !important;
    }
  }
`

export const TextWrapper = styled.div`
  padding: 45px 30px 46px;
  background: ${(props) => props.theme.colors.blue};

  > svg {
    position: absolute;
    top: 157px;
    right: -158px;
    width: 300px;
    height: 487px;
    opacity: 0.1;
    z-index: 0;
    @media ${device.desktop} {
      top: -119px;
      right: -163px;
      width: 300px;
      height: 487px;
    }
  }
  @media ${device.tablet} {
    width: 81%;
    padding: 50px;
    margin-left: 21%;
    padding-left: calc(25% + 45px);
    padding-top: 85px;
    padding-bottom: 80px;
  }

  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 117%;
    letter-spacing: 0.01em;
    color: #ffffff;

    margin-bottom: 20px;
    width: 100%;

    @media ${device.desktop} {
      font-size: 36px;
      margin-bottom: 9px;
    }

    a {
      color: inherit;
      width: 100%;

      :before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
      }
    }
  }

  p {
    font-style: 400;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;

    width: 100%;
    margin-bottom: 15px;
    @media ${device.desktop} {
      font-weight: 300;
      font-size: 18px;
      line-height: 150%;
      margin-bottom: 20px;
    }
  }

  > *:last-child {
    margin-bottom: 0;
  }
`

export const ImgTextButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-right: 15px;
  }

  span.button-link {
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    display: block;

    position: relative;
    ::before {
      position: absolute;
      content: "";
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 1px;
      background: #fff;
      opacity: 0;
    }
  }
`
