import React from "react"
import AvenueLink from "ui/AvenueLink"
import AvenueImg from "ui/AvenueImg"
import { Helmet } from "react-helmet"

import { Icon } from "ui"
import {
  ImgTextCustomContainer,
  ImgWrapper,
  TextWrapper,
  ImgTextButton,
} from "./style"

const ImgTextCustom = ({ img, link, linkText, heading, message }) => {
  return (
    <ImgTextCustomContainer>
      {img.sourceUrl && (
        <ImgWrapper>
          <AvenueImg fluid={{ ...img, sizes: "100vw", aspectRatio: 1 }} />
        </ImgWrapper>
      )}

      <TextWrapper>
        <Icon name={"leaf"} width={[350]} heigh={[400]} />
        <h3>
          <AvenueLink to={link}>{heading}</AvenueLink>
        </h3>
        <p>{message}</p>
        <ImgTextButton variant="ArrowOrange">
          <Icon name={"arrow_orange"} width={[24]} heigh={[24]} />
          <span className="button-link">{linkText}</span>
        </ImgTextButton>
      </TextWrapper>
    </ImgTextCustomContainer>
  )
}

export default ImgTextCustom
