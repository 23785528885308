import React, { useState } from "react"

import { Icon } from "helpers/getIcon"
import {
  CustomEmbeddedVideoContainer,
  CustomembeddedVideoButtonContainer,
  ReactVideo,
  VideoContainer,
  Thumbnail,
  VideoImageContainer,
} from "./style"

const CustomEmbeddedVideo = ({
  thumbnailSrcSet,
  thumbnailURL,
  video,
  shouldPlay,
}) => {
  const [showVideo, setShowVideo] = useState(shouldPlay)
  const shouldMute = shouldPlay ? true : false
  const btnIcon = <Icon name={"video-play"} />
  const wpImg = {
    sourceUrl: thumbnailURL,
    srcSet: thumbnailSrcSet,
    sizes: "100vw",
    aspectRatio: 1.77,
  }

  return (
    <CustomEmbeddedVideoContainer onClick={() => setShowVideo(true)}>
      <VideoImageContainer>
        <Thumbnail
          fluid={wpImg}
          showVideo={showVideo}
          shouldPlay={shouldPlay}
        />
        <CustomembeddedVideoButtonContainer shouldPlay={shouldPlay}>
          {btnIcon}
          <p>Play video</p>
        </CustomembeddedVideoButtonContainer>
        <VideoContainer showVideo={showVideo} shouldPlay={shouldPlay}>
          <ReactVideo
            controls={true}
            url={video}
            playing={showVideo}
            volume={1}
            muted={shouldMute}
          />
        </VideoContainer>
      </VideoImageContainer>
    </CustomEmbeddedVideoContainer>
  )
}

export default CustomEmbeddedVideo
