import React, { useContext } from "react"
import { parseBlockContent } from "helpers/parseBlockContent"
import { PreviewContext } from "context/PreviewContext"
import RelatedPages from "./RelatedPages"
import { Content } from "ui/Content"
import { InternalTemplateContainer } from "./style"
import { Grid, Row, Col } from "ui/Grid"
import InternalNav from "./InternalNav/InternalNav"
import { SidebarButtons } from "./SidebarButtons"
import InternalForm from "./InternalForm"
import AvenueImg from "ui/AvenueImg"

const Internal = ({ data }) => {
  const { content, avForm } = data
  const { previewData } = useContext(PreviewContext)

  const parsedContent =
    previewData?.templateData?.content || content
      ? parseBlockContent(content)
      : null

  const pageSettingsData =
    previewData?.templateData?.pageSettings || data.pageSettings

  return (
    <InternalTemplateContainer>
      <Grid>
        <Row>
          <Col xs={12} md={8}>
            <Content>{parsedContent}</Content>
            <InternalForm data={avForm} />
          </Col>
          <Col xs={12} mdOffset={1} md={3}>
            <InternalNav>
            {pageSettingsData?.relatedPages?.length > 0 && (
              <SidebarButtons data={pageSettingsData.relatedPages} />
            )}
            </InternalNav>
          </Col>
        </Row>
      </Grid>

    </InternalTemplateContainer>
  )
}

export default Internal
