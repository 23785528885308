import React, { useContext } from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { graphql } from "gatsby"
import { Internal } from "screens/Internal"

const page = ({ data }) => {
  const { seo, featuredImage } = data.page

  return (
    <Layout internal pageContext={data.page}>
      <SEO
        title={seo.title}
        image={featuredImage?.node?.sourceUrl}
        description={seo.metaDesc}
      />
      <Internal data={data.page} />
    </Layout>
  )
}

export default page

export const pageQuery = graphql`
  query($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      content
      uri
      seo {
        title
        metaDesc
      }
      headerSettings {
        customPageTitle
        hdDescription
      }

      avForm {
        fmHeading
        fmDescription
        showForm
      }
      ancestors {
        nodes {
          ... on WpPage {
            depth
            title
            uri
          }
        }
      }
      featuredImage {
        node {
          sourceUrl
          srcSet
          mediaDetails {
            height
            width
          }
        }
      }
      pageSettings {
        relatedPages {
          ... on WpPage {
            title
            excerpt
            uri
            featuredImage {
              node {
                sourceUrl
                srcSet
                mediaDetails {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`
