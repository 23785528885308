import React from "react"
import { DoubleImgCustomContainer, ImageWrapper } from "./style"
import AvenueImg from "ui/AvenueImg"

const DoubleImgCustom = ({ images }) => {
  const { imageLeft, imageRight, aspectRatio } = images

  return (
    <DoubleImgCustomContainer>
      <ImageWrapper>
        <AvenueImg
          fluid={{
            ...imageLeft,
            aspectRatio: parseFloat(aspectRatio),
            sizes: "(max-width: 1024px) 100vw, 550px",
          }}
        />
      </ImageWrapper>

      <ImageWrapper>
        <AvenueImg
          fluid={{
            ...imageRight,
            aspectRatio: parseFloat(aspectRatio),
            sizes: "(max-width: 1024px) 100vw, 550px",
          }}
        />
      </ImageWrapper>
    </DoubleImgCustomContainer>
  )
}

export default DoubleImgCustom
