import React, { useState } from "react"
import { Link } from "gatsby"
import { Box, Text, Button } from "ui"
import Form from "shared/Form"

const InternalForm = ({ data }) => {
  const [submitted, setSubmitted] = useState(false)
  if (data.showForm) {
    return (
      <Box
        pt={[40, null, 64]}
        pb={[20, 50, 100]}
        borderTop={[null, null, "2px solid #E5E5E5"]}
      >
        <Text as="h2" mb={[15, null, 19]}>
          {data.fmHeading}
        </Text>
        <Text mb={[25, null, 35]} color="#4A4A4A">
          {data.fmDescription}
        </Text>

        <Form
          variant="work"
          setSubmitted={setSubmitted}
          submitted={submitted}
        />
      </Box>
    )
  } else return null
}

export default InternalForm
