import styled from "styled-components"
import { device } from "breakpoints"
import { Carousel } from "ui/Carousel"
import { Dots, PreviousButton, NextButton } from "ui/Carousel/style"
import AvenueImg from "ui/AvenueImg"

export const GalleryCarousel = styled(Carousel)`
  position: relative;
  margin: 25px 0;

  @media ${device.desktop} {
    margin: 50px 0;
  }
  ${Dots} {
    position: absolute;
    bottom: 15px;
    z-index: 10;

    @media ${device.desktop} {
      bottom: 40px;
    }
  }

  ${PreviousButton} {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    border-radius: 0;
    background: none;
    z-index: 3;

    svg {
      display: none;
    }
  }

  ${NextButton} {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    z-index: 3;
    border-radius: 0;
    background: none;
    svg {
      display: none;
    }
  }

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 77%,
      rgba(0, 0, 0, 0.35) 100%
    );
  }
`
export const SlideImg = styled(AvenueImg)`
  position: absolute !important;
  top: 0;
  left: 0;
  object-fit: cover !important;
  width: 100%;
  height: 100%;
  z-index: 2;
  /* background: Teal; */
`
