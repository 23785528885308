import React from "react"
import { GalleryCarousel, SlideImg } from "./style"

const Gallery = ({ imgs }) => {
  return (
    <>
      {imgs?.length && (
        <GalleryCarousel autoPlay interval={4} fade dotColor="#9E1164" loop>
          {imgs.map((img, index) => {
            return (
              <SlideImg
                fluid={{
                  sourceUrl: img.src,
                  srcSet: img.srcSet,
                  sizes: "(min-width: 1024px) 1160px, 100vw",
                  aspectRatio: 1.54,
                }}
                key={index}
              />
            )
          })}
        </GalleryCarousel>
      )}
    </>
  )
}

export default Gallery
