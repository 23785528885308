import React from "react"
import { RelatedPagesContainer } from "./style"
import { Grid, Row, Col } from "ui/Grid"
import { Teaser } from "ui/Teaser"

const RelatedPages = ({ pages }) => {
  return (
    <RelatedPagesContainer>
      <h3>Related Pages</h3>
      <Grid>
        <Row>
          {pages.map((page, index) => {
            return (
              <Col xs={12} md={4} key={index}>
                <Teaser
                  link={page.uri}
                  title={page.title}
                  excerpt={page.excerpt}
                  img={{ ...page.featuredImage?.node, aspectRatio: 1.4 }}
                />
              </Col>
            )
          })}
        </Row>
      </Grid>
    </RelatedPagesContainer>
  )
}

export default RelatedPages
