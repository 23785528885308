import styled from "styled-components"
// import { device } from "breakpoints"
import { MainColumn } from "ui/MainColumn"

export const InternalTemplateContainer = styled(MainColumn)``

export const RelatedPagesContainer = styled.div`
  margin: 50px 0;
  border: 1px dashed teal;

  h3 {
    margin-bottom: 30px;
  }
`
