import styled from "styled-components"
import { device } from "breakpoints"

export const DoubleImgCustomContainer = styled.div`
  display: flex;
  margin: 30px auto;

  @media ${device.desktop} {
    margin: 60px auto;
  }
`

export const ImageWrapper = styled.div`
  ${'' /* padding-top: 47.4%; */}
  flex-basis: 50%;
  position: relative;
  :first-of-type {
    margin-right: 15px;

    @media ${device.tablet} {
      margin-right: 15px;
    }
    @media ${device.desktop} {
      margin-right: 30px;
    }
  }

  :last-of-type {
    margin-left: 15px;

    @media ${device.tablet} {
      margin-left: 15px;
    }

    @media ${device.desktop} {
      margin-left: 30px;
    }
  }

  h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  ${'' /* .avenueImg {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  } */}
`
